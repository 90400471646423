import { useMediaQuery } from '@mui/material'
import { getRedirectResult, GoogleAuthProvider, signInWithEmailAndPassword, signInWithRedirect } from 'firebase/auth'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFeatureFlag } from '../Containers/FeatureFlag'
import { Flows, log, LogAction, LogCategory, logSentryError, logSentryMessage } from '../utils/logHelper'
import { zimBackendAuth } from './'

const googleAuthProvider = new GoogleAuthProvider()
googleAuthProvider.setCustomParameters({ prompt: 'select_account' })

type LoginState = 'submitting' | 'submitting-sso' | 'not-loggedin' | 'not-allowed'

/**
 * Hook for email password login
 */
export const useLogin = () => {
  const isSmallScreen = useMediaQuery('(max-width:800px)')
  const isTouchDevice = useMediaQuery('(hover: none) and (pointer: coarse)')
  const {
    values: { isEmailLoginEnabled, isSSOLoginEnabled },
  } = useFeatureFlag(['isEmailLoginEnabled', 'isSSOLoginEnabled'])

  const [email, setEmail] = useState(process.env.REACT_APP_LOGIN_EMAIL || '')
  const [password, setPassword] = useState(process.env.REACT_APP_LOGIN_PASSWORD || '')
  const [loginState, setLoginState] = useState<LoginState>('not-loggedin')
  const [errorState, setErrorState] = useState('')

  const logoutIframeRef = useRef<HTMLIFrameElement | null>(null)

  // handle logout from google using an iframe (only for ZIPP)
  // https://groups.google.com/g/firebase-talk/c/POJl8-b4OJs
  useEffect(() => {
    if (!logoutIframeRef.current) return
    if (!isSmallScreen) return
    if (!isTouchDevice) return
    logoutIframeRef.current.src =
      'https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout'
  }, [isSmallScreen, isTouchDevice])

  // handle SSO login after redirection
  useEffect(() => {
    getRedirectResult(zimBackendAuth)
      .then(async (result) => {
        if (!result) return
        logSentryMessage('SSO Login successful')
      })
      .catch(handleLoginError)
  }, [])

  const handleLoginWithUsernameAndPassword = async (email: string, password: string): Promise<void> => {
    setLoginState('submitting')

    try {
      await signInWithEmailAndPassword(zimBackendAuth, email, password)
      logSentryMessage('Email/Password Login successful')
    } catch (error) {
      setLoginState('not-allowed')
      handleLoginError(error)
    }
  }

  const handleLoginWithSSO = async () => {
    setLoginState('submitting-sso')

    try {
      await signInWithRedirect(zimBackendAuth, googleAuthProvider)
    } catch (error) {
      setLoginState('not-loggedin')
      handleLoginError(error)
    }
  }

  // handle errors
  const handleLoginError = useCallback((error: unknown) => {
    logSentryError(error, {
      flow: Flows.LOGIN,
    })
    log(LogCategory.AUTH, LogAction.LOGIN, 'Failed to log in', {
      error,
    })
    if (error instanceof Error) {
      setErrorState(error.message)
    }
  }, [])

  return {
    isSmallScreen,
    isTouchDevice,
    isEmailLoginEnabled,
    isSSOLoginEnabled,
    email,
    password,
    isDisabled: ['submitting', 'submitting-sso'].includes(loginState),
    loginState,
    errorState,
    logoutIframeRef,
    setEmail,
    setPassword,
    handleLoginWithUsernameAndPassword,
    handleLoginWithSSO,
  }
}
